import gsap from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';

gsap.registerPlugin(TextPlugin);
gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    lazySizes.init();
};

require('doc-ready')(init);
