import gsap from 'gsap';
import $ from '../core/Dom';

export default (el, props) => {
    const dom = $(el);
    const live = dom.find('[aria-live]');
    const target = dom.find('[data-target]');

    const rotateWords = () => {
        props.push(props.shift());
        return props[0];
    };

    const initWord = () => {
        const word = props[0];
        live.text(word);
        gsap.to(target.get(0), {
            text: {
                value: word.substring(2),
                speed: 0.8
            },
            duration: 2,
            repeat: 1,
            yoyo: true,
            repeatDelay: 1.5,
            ease: 'none',
            onComplete: () => {
                setTimeout(() => {
                    rotateWords();
                    initWord();
                }, 150)
            }
        });
    };

    const onFocus = () => {
        live.attr('aria-live', 'polite');
    };

    const onBlur = () => {
        live.attr('aria-live', 'off');
    };

    const init = () => {
        dom.on('focus', onFocus);
        dom.on('blur', onBlur);
        initWord();
    };

    const destroy = () => {
        dom.off('focus', onFocus);
        dom.off('blur', onBlur);
    };

    return {
        init,
        destroy
    };
};
