import $ from '../core/Dom';
import gsap from "gsap";

const loadFlickity = require('bundle-loader?lazy&name=[name]!flickity');

export default el => {
    let flkty;
    let index = 0;
    let timeline;
    let seeking = false;

    const DEFAULT_DURATION = 5;
    const dom = $(el);
    const wrapper = dom.find('[data-slides-wrapper]');
    const bars = dom.find('[data-progress]');
    const barsButtons = dom.find('[data-bars-wrapper] button');
    const numberOfSlides = bars.length - 1;

    const fixAriaHidden = () => {
        if (flkty) {
            flkty.cells.forEach(({ element }) => {
                element.removeAttribute('aria-hidden');
            });
        }
    };

    const updateIndex = value => {
        index = value;
        if (index > numberOfSlides) {
            index = 0;
        } else if (index < 0) {
            index = numberOfSlides;
        }
        seeking = true;
        const progress = (index / (numberOfSlides + 1) + 0.005);
        timeline.progress(progress);
        setTimeout(() => {
            seeking = false;
        }, 100);
        return index;
    };

    const next = () => {
        updateIndex(index + 1);
        flkty.next();
    };


    const play = () => {
        timeline.play();
    };

    const pause = () => {
        timeline.pause();
    };

    const jumpTo = e => {
        const oldIndex = index;
        const newIndex = barsButtons.nodes.indexOf(e.target);
        if (oldIndex !== newIndex) {
            index = updateIndex(newIndex);
            flkty.select(index);
        }
    };

    const onSelect = (index) => {
        fixAriaHidden();
        if (!seeking) {
            console.log('onSelect: ', index);
            updateIndex(index);
        }
    };

    const onComplete = () => {
        timeline.restart();
        next();
    };

    const initTimeline = () => {
        timeline = gsap.timeline({ paused: true });

        bars.each((bar, i) => {
            const isLastSlide = i === numberOfSlides;
            const duration = DEFAULT_DURATION;
            timeline.to(bar, {
                scaleX: 1,
                duration,
                ease: 'linear',
                onComplete: () => {
                    if (!seeking) {
                        if (isLastSlide) {
                            onComplete();
                        } else {
                            next();
                        }
                    }
                }
            });
        });
        timeline.play();
    };

    const init = () => {
        loadFlickity(Flickity => {
            wrapper.removeClass('overflow-auto').addClass('overflow-hidden');
            flkty = new Flickity(wrapper.get(0), {
                contain: true,
                dragThreshold: 15,
                cellAlign: 'left',
                prevNextButtons: false,
                pageDots: false,
                wrapAround: true,
            });

            flkty.on('dragStart', () => {
                document.ontouchmove = e => e.preventDefault();
                flkty.slider.style.pointerEvents = 'none';
            });

            flkty.on('dragEnd', () => {
                document.ontouchmove = () => true;
                flkty.slider.style.pointerEvents = 'auto';
            });

            flkty.on('select', onSelect);
            flkty.on('ready', fixAriaHidden);

            dom.on('focusin', e => {
                wrapper.get(0).parentNode.scrollLeft = 0;
                const { triggerTarget: link } = e;
                const cell = flkty.cells.find(({ element }) => element.contains(link));
                if (!cell) {
                    return;
                }
                const index = flkty.cells.indexOf(cell);
                if (index > -1) {
                    flkty.selectCell(index);
                }
            });

            /*dom.on('mouseenter', e => {
                pause();
            });

            dom.on('mouseleave', e => {
                play();
            });*/

            initTimeline();
            barsButtons.on('click', jumpTo);
        });
    };

    const destroy = () => {
        if (flkty) {
            flkty.destroy();
            flkty = null;
            barsButtons.off('click', jumpTo);
        }
    };

    return {
        init,
        destroy
    };
};
