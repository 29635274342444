import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {
    const dom = $(el);
    const button = dom.find('[data-button]');
    const embed = dom.find('[data-embed]');

    const loadPlayer = e => {
        e.preventDefault();
        button.off('click', loadPlayer);
        embed.html(embed.data('embed'));
        gsap.to(embed.get(0), {
            duration: 1,
            autoAlpha: 1,
            ease: 'power2.inOut'
        });
    };

    const init = () => {
        button.on('click', loadPlayer);
    };

    const destroy = () => {
        button.off('click', loadPlayer);
    };

    return {
        init,
        destroy
    };
};
