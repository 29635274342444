import $ from '../core/Dom';

export default el => {
    let video;

    const onTimeUpdate = () => {
        if (video.currentTime < 0.001) {
            return;
        }
        video.removeEventListener('timeupdate', onTimeUpdate);
        video.classList.remove('opacity-0');
    };

    const onMuxReady = () => {
        video = el.firstElementChild;
        if (video) {
            video.addEventListener('timeupdate', onTimeUpdate);
        }
    };

    const init = () => {
        window.customElements.whenDefined('mux-video')
            .then(() => {
                onMuxReady();
            })
            .catch(error => {
                console.error(error);
            });
    };

    const destroy = () => {
        if(video) {
            video.removeEventListener('timeupdate', onTimeUpdate);
        }
    };

    return {
        init,
        destroy
    };
};
